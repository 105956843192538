import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          arrow: false,
          autoplay: true,
        },
      },
    ],
  };

  const testimonialContent = [
    {
      img: "cli1.jpg",
      desc: `"Commerce professor by profession, fitness model by passion, all credit goes to you, 'shape me'. Thank you for your guidance." `,
      name: "Sooraj Pattnaik",
      designation: "Marketing Manager",
    },
    {
      img: "cli7.jpeg",

      desc: `Here's a corrected version of your sentence: "I never thought I would achieve my shredded physique at the age of 41. This became possible all because of 'shapeme'. `,
      name: "Montu das",

      designation: "App Developer",
    },
    {
      img: "cli2.jpeg",

      desc: `Being underweight since childhood, I never thought I would become this muscular. It's like a dream come true. Thank you, 'shapeme', for making it a reality."
   
     `,
      name: "Rudra Mishra",
      designation: "Photographer",
    },
    {
      img: "cli3.jpg",

      desc: `These people really know what they are doing! Great customer support availability and supperb kindness. I am very happy that I've purchased this liscense!!!`,
      name: "Siddhant Mishra",
      designation: "App Developer",
    },
    {
      img: "cli4.jpg",

      desc: `Here's the corrected sentence: "Deciding to get out of obesity and choosing 'shape me' for this was the best decision ever; I shed 22kg of fat,thankyou shape me.”
     `,
      name: "Anil Sharma",
      designation: "App Developer",
    },
    {
      img: "cli5.jpg",
      desc: `"Being a software engineer, it seemed impossible to manage office work and find time to get in shape, but 'shape me' made the process so smooth. Thank you, always grateful."  `,
      name: "Abhishek agrawal",
      designation: "App Developer",
    },
    {
      img: "cli6.jpeg",

      desc: `"Thank you, sir. I never thought I would be able to lose 21kg in just a span of 3 months. Always grateful." 
    `,
      name: "aman goyal",
      designation: "App Developer",
    },
  ];

  return (
    <Slider {...settings}>
      {testimonialContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="list_inner">
            <div className="left">
              <img src="img/thumbs/1-1.jpg" alt="tumb" />
              <div
                className="main"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + ` /img/sid/Clients/${val.img}`
                  })`,
                }}
              ></div>
            </div>
            <div className="right">
              <img className="svg" src="img/svg/quote.svg" alt="quote" />
              <p className="text">{val.desc}</p>
              <div className="details">
                <h3 className="name">
                  <span>{val.name}</span>
                </h3>
                {/* <span className="job">{val.designation}</span> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}
