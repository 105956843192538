import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderAnimation";
import About from "../../components/about/AboutAnimation";
import Service from "../../components/service/ServiceAnimation";
import Portfolio from "../../components/portfolio/PortfolioAnimation";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/BlogAnimation";
import Contact from "../../components/Contact";
import Footer from "../../components/footer/Footer";
import Address from "../../components/Address";
import Bmr from "../../components/Bmr/Bmr";
import Skills from "../../components/skills/SkillsAnimation";
const HomeLightAnimation = () => {
  document.body.classList.add("light");
  return (
    <div className="home-light">
      <div
        data-aos="fade-left"
        data-aos-duration="1200"
        data-aos-delay="200"
        className="whatsapp-icon"
      >
        <a
          target="blank"
          href="https://wa.me/+918249231436?text=Hi,My name is [Your Name], and I'm reaching out to inquire about the packages you offer for Coaching Plans.?"
        >
          <img src="img/sid/whatsapp.png" alt="" />
        </a>
      </div>

      <Header />
      {/* End Header */}

      <Slider />
      {/* End Slider */}

      <div className="beny_tm_about" id="about">
        <div className="container">
          <div className="beny_tm_title_holder">
            <span>About Me</span>
          </div>
          {/* End .beny_tm_title */}
          <About />
          {/* End Slider */}
        </div>
      </div>

      <div className="beny_tm_services" id="coaching">
        <div className="container">
          {/* End .beny_tm_title */}
          <Skills />
          {/* End Slider */}
        </div>
      </div>

      {/* /ABOUT */}

      {/* SERVICES */}
      <div className="beny_tm_services" id="service">
        <div className="container">
          <div className="beny_tm_title_holder">
            <span>E-Diet Plans</span>
            <h2>Fuel Your Journey:</h2>
            <p>Transformative E-Diet Plans for a Balanced Life.</p>
          </div>
          {/* End beny_tm_title */}
          <Service />
        </div>
      </div>
      {/* /SERVICES */}

      {/* BMR */}

      <div className="beny_tm_services" id="BMR">
        <div className="container">
          <div className="beny_tm_title_holder">
            <span>BMI CALCULATOR</span>
          </div>

          <Bmr />
        </div>
      </div>

      {/* NEWS */}
      <div className="beny_tm_news" id="news">
        <div className="container">
          <div className="beny_tm_title_holder">
            <span>Blogs</span>
            <h2>Recent News</h2>
            <p>
              Highlighted in the news for aiding over 3000 individuals in
              getting fit and raising awareness about the critical role of
              nutrition in tackling obesity and enhancing overall health and
              wellness.
            </p>
          </div>
          {/* End beny_tm_title */}
          <Blog />
        </div>
      </div>

      {/* PORTFOLIO */}
      <div className="beny_tm_portfolio" id="portfolio">
        {/* TESTIMONIALS */}
        <div className="beny_tm_testimonials">
          <div className="container">
            <div className="beny_tm_title_holder">
              <h2>What's Clients Say.</h2>
              {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet,
              egestas. Id fermentum nullam ipsum massa.
            </p> */}
            </div>
            {/* End beny_tm_title */}
            <div
              className="testimonials_list"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <Testimonial />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="beny_tm_title_holder">
            <span>TRANSFORMATIONS</span>
            <h2>Client Transformations</h2>
            <p className="trans-para1">
              500+ Lives Transformed : Your Goals. my expertise - unstoppable
              together
            </p>
          </div>
          {/* End beny_tm_title */}
          <Portfolio />
        </div>
      </div>
      {/* /PORTFOLIO */}

      {/* /TESTIMONIALS */}

      {/* /NEWS */}

      {/*  CONTACT */}
      <div className="beny_tm_contact" id="contact">
        <div className="container">
          <div className="contact_inner">
            <div
              className="left"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="beny_tm_title_holder">
                <span>Contact</span>
                <h2>Contact Me</h2>
              </div>
              <div className="short_list">
                <Address />
              </div>
            </div>
            {/* End .left */}

            <div
              className="right"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <div className="title">
                <p>
                  I'm always open to help you.
                  {/* <br />
                  <span>design work or partnerships.</span> */}
                </p>
              </div>
              <div className="fields">
                <Contact />
              </div>
            </div>
          </div>

          <div className="map-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7464.195372020993!2d83.50079374180298!3d20.70625746240205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a240f7d115f49bd%3A0xea3786e549058d67!2sShape%20me%20(Health%20%26%20Wellness%20Consultancy%20)!5e0!3m2!1sen!2sin!4v1708530517267!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      {/* /CONTACT */}

      {/* COPYRIGHT */}
      <div className="beny_tm_copyright">
        <div className="container">
          <Footer />
        </div>
      </div>
      {/* /COPYRIGHT */}
    </div>
  );
};

export default HomeLightAnimation;
