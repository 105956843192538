import React from "react";
import Header2 from "../../components/header/Header2";

const Refund = () => {
  return (
    <>
      <Header2 />

      <div className="policy-d1">
        <div className="policy-d2">
          <h1>Refund & Cancellation Policy</h1>

          <div className="policy-details">
            <p>
              We at TheShapeMe understand that circumstances may arise where you
              need to cancel your enrollment in our non-refundable course.
              Please carefully read and adhere to the following policy regarding
              refunds and cancellations:
            </p>
            <h1>Cancellation Policy:</h1>

            <p>
              Once enrolled in our non-refundable course, cancellations are not
              permitted. No refunds or credits will be issued for any reason,
              including but not limited to changes in personal circumstances,
              unforeseen events, or inability to continue with the course.
            </p>

            <h1>Transferability:</h1>

            <p>
              Enrollments in our non-refundable course are non-transferable. The
              course is specifically designed for the registered participant,
              and the access and benefits associated with it cannot be
              transferred to another individual.
            </p>
            <h1>Course Content:</h1>
            <p>
              Access to course materials, resources, and any associated benefits
              will be provided exclusively to the enrolled participant. Sharing
              or distributing course content, login credentials, or any related
              materials with others is strictly prohibited and may result in
              immediate termination of access without refund.
            </p>

            <h1>Technical Issues:</h1>
            <p>
              TheShapeMe is committed to providing a seamless online learning
              experience. In the unlikely event of technical issues, we will
              make reasonable efforts to resolve them promptly. However,
              technical issues will not be considered grounds for a refund or
              cancellation.
            </p>
            <h1>Modification or Termination:</h1>
            <p>
              TheShapeMe reserves the right to modify, suspend, or terminate the
              non-refundable course at any time. In such cases, participants
              will be notified, and efforts will be made to provide alternative
              solutions or compensation.
            </p>
            <h1>Contact Information:</h1>
            <p>
              For any inquiries or concerns related to the non-refundable
              course, please contact our customer support at
              [contact@theshapeme.com]. By enrolling in TheShapeMe's
              non-refundable course, you acknowledge and agree to abide by the
              terms and conditions outlined in this Refund & Cancellation
              Policy. We appreciate your understanding and commitment to your
              learning journey.
            </p>
          </div>

          <p></p>
        </div>
      </div>
    </>
  );
};

export default Refund;
