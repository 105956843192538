import React, { useState } from "react";
import emailjs from "@emailjs/browser";

import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!name | !email | !message | !contact) {
      toast.error("Fill Required Fields");
    } else {
      const data = {
        name,
        email,
        message,
        contact,
      };

      try {
        await emailjs.send(
          "service_r5p6n4h",
          "template_jw3rz6u",
          data,
          "CD2QmSOicWFjOHqSK"
        );
        toast.success("Successfully Send");
        setName("");
        setEmail("");
        setMessage("");
        setContact("");
      } catch (error) {
        console.error("Email error:", error);
      }
    }
  };

  return (
    <>
      <form className="contact_form" onSubmit={handleFormSubmit}>
        <div className="first_row">
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Name *"
          />
        </div>

        <div className="second">
          <div className="left">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email *"
            />
          </div>
          <div className="right">
            <input
              placeholder="Contact *"
              type="number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
        </div>
        {/* End .second */}

        <div className="third">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Message *"
          ></textarea>
        </div>
        {/* End .third */}

        <div className="beny_tm_button">
          <button type="submit" className="color">
            <span className="wrapper">
              <span className="first">Submit</span>
              <span className="second">Submit</span>
            </span>
          </button>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
