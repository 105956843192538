import React from "react";
import Header2 from "../../components/header/Header2";

const Privacy = () => {
  return (
    <>
      <Header2 />

      <div className="policy-d1">
        <div className="policy-d2">
          <h1>Privacy Policy</h1>

          <div className="policy-details">
            <h1>Privacy Policy:</h1>
            <p>
              Please read the following Privacy Policy carefully before using
              TheShapeMe's services or participating in any courses. By
              accessing our website or enrolling in our courses, you agree to
              comply with and be bound by this policy.
            </p>
            <h1>Collection of Information:</h1>
            <p>
              TheShapeMe collects personal information, including but not
              limited to, names, contact information, and payment details, for
              the purpose of providing our services. We are committed to
              protecting your privacy and ensuring the confidentiality of your
              information.
            </p>
            <h1>Use of Information:</h1>
            <p>
              The information collected may be used to process payments, enroll
              you in courses, and provide you with relevant updates and
              communications. We do not sell, rent, or share your personal
              information with third parties for marketing purposes.
            </p>
            <h1>Security:</h1>
            <p>
              TheShapeMe employs industry-standard security measures to
              safeguard your personal information. However, no method of
              transmission over the internet or electronic storage is completely
              secure, and we cannot guarantee absolute security.
            </p>
            <h1>Cookies:</h1>
            <p>
              Our website may use cookies to enhance your user experience. You
              have the option to disable cookies in your browser settings, but
              this may affect the functionality of certain features.
            </p>
            <h1>Third-Party Links:</h1>
            <p>
              Our website may contain links to third-party websites. Please be
              aware that we are not responsible for the privacy practices of
              such sites. We encourage users to read the privacy policies of any
              third-party sites they visit.
            </p>
            <h1>Changes to the Privacy Policy:</h1>
            <p>
              TheShapeMe reserves the right to update or change this Privacy
              Policy at any time. Users will be notified of any significant
              changes, and continued use of our services implies acceptance of
              the modified policy.
            </p>
            <h1>Contact Information:</h1>
            <p>
              For any inquiries or concerns related to our Privacy Policy,
              please contact our privacy team at @theshapeme.com.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
