import React from "react";
import { IoArrowRedoSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div className="footer-section1">
        <div className="footer-d1">
          <h1>CONTACT ME</h1>
          <p>For our customized online transformation program.</p>

          <div className="footer-d2">
            <a href="https://wa.me/+918249231436?text=Hi,My name is [Your Name], and I'm reaching out to inquire about the packages you offer for Coaching Plans.?">
              Click Here
              <IoArrowRedoSharp />
            </a>
          </div>
        </div>

        <div className="footer-d3">
          <h1>POLICY LINKS</h1>
          <ul>
            <li>
              <Link to={"/Refund&CancellationPolicy"}>Refund Policy</Link>
            </li>
            <li>
              <Link to={"/Terms&Conditions"}>Terms & Conditions</Link>
            </li>
            <li>
              <Link to={"/PrivacyPolicy"}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="inner">
        <div className="copy">
          <p>
            &copy; {new Date().getFullYear()} by The Shape Me . All rights
            reserved.
          </p>
          <p>
            Designed By {""}
            <a href="https://ashishkhanna.in/" target="_blank" rel="noreferrer">
              Ashish Khanna
            </a>{" "}
            {""}
            <a href="Tel: 917018548021" rel="noreferrer">
              (+91 7018548021)
            </a>
            <p>
              <a
                href="https://ashishkhanna.in/"
                target="_blank"
                rel="noreferrer"
              >
                For Websites & App Contact
              </a>
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
