import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link, NavLink } from "react-router-dom";
import {
  FiHome,
  FiUser,
  FiSettings,
  FiGrid,
  FiCast,
  FiPhoneOutgoing,
} from "react-icons/fi";
import { BsYoutube } from "react-icons/bs";

import { FiMenu } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import { styled } from "styled-components";

import { RiMenu3Fill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: white;
  z-index: 15;
  color: white;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const [open, setopen] = useState(false);
  const Icon = open ? GrClose : RiMenu3Fill;

  const SocialShare = [
    {
      iconName: <FiInstagram />,
      link: "https://www.instagram.com/shapeme_diet_expert/",
    },
    {
      iconName: <BsYoutube />,
      link: "https://youtube.com/@shapeme4091?si=EeaT9fyojQd4G4UB",
    },
    {
      iconName: <FiLinkedin />,
      link: "https://www.linkedin.com/feed/",
    },
  ];

  return (
    <>
      {/* TOPBAR  */}
      <div className={navbar ? "beny_tm_topbar animate" : "beny_tm_topbar"}>
        <div className="in">
          <div>
            <h1
              style={{ color: navbar ? "black" : "white" }}
              className="nav-head"
            >
              India's Leading Health & Wellness Visionary
            </h1>
            {/* <h1 className="nav-head">
              India's first one-stop solution destination for health & fitness.
            </h1> */}
          </div>
          <div className="topbar_inner">
            <div className="logo">
              <img src="img/sid/logo/logo.jpeg" alt="brand" />
            </div>

            <div className="menu-open">
              <FiMenu
                onClick={() => setopen(!open)}
                color={navbar ? `black` : `white`}
                size={30}
              />
            </div>

            <div className="menu">
              <Scrollspy
                className="anchor_nav"
                items={[
                  "home",
                  "about",
                  "service",
                  // "Supplements",
                  "coaching",
                  "portfolio",
                  "news",
                  "contact",
                ]}
                currentClassName="current"
                offset={-88}
              >
                <li>
                  <a className={` ${navbar ? "nav-a" : ""}`} href="#home">
                    <span className="first">Home</span>
                    <span className="second">Home</span>
                  </a>
                </li>
                <li>
                  <a className={` ${navbar ? "nav-a" : ""}`} href="#about">
                    <span className="first">About</span>
                    <span className="second">About</span>
                  </a>
                </li>
                <li>
                  <a className={` ${navbar ? "nav-a" : ""}`} href="#service">
                    <span className="first">E-Diet Plans</span>
                    <span className="second">E-Diet Plans</span>
                  </a>
                </li>
                {/* <li>
                  <NavLink
                    to={"/Supplements"}
                    className={` ${navbar ? "nav-a" : ""}`}
                  >
                    <span className="first">Supplements</span>
                    <span className="second">Supplements</span>
                  </NavLink>
                </li> */}
                <li>
                  <a className={` ${navbar ? "nav-a" : ""}`} href="#coaching">
                    <span className="first">Coaching</span>
                    <span className="second">Coaching</span>
                  </a>
                </li>
                <li>
                  <a className={` ${navbar ? "nav-a" : ""}`} href="#portfolio">
                    <span className="first">Transformations</span>
                    <span className="second">Transformations</span>
                  </a>
                </li>
                <li>
                  <a className={` ${navbar ? "nav-a" : ""}`} href="#news">
                    <span className="first">News</span>
                    <span className="second">News</span>
                  </a>
                </li>
                <li>
                  <a className={` ${navbar ? "nav-a" : ""}`} href="#contact">
                    <span className="first">Contact</span>
                    <span className="second">Contact</span>
                  </a>
                </li>
            
              </Scrollspy>
            </div>
          </div>
        </div>
      </div>

      <StyledOffCanvas isOpen={open}>
        <div className="side-menu-1">
          <div className="close-side-bar">
            <AiOutlineClose
              onClick={() => setopen(false)}
              color={"black"}
              size={30}
            />
          </div>
          <div className="side-menu-logo">
            <img src="/img/sid/logo/logo1.jpeg" alt="" />
          </div>

          <div className="mobile-menu">
            <div className="mobile-menu2">
              <li onClick={() => setopen(false)}>
                <a className={` ${navbar ? "nav-a" : ""}`} href="#home">
                  <span className="first">Home</span>
                </a>
              </li>
              <li onClick={() => setopen(false)}>
                <a className={""} href="#about">
                  <span className="first">About</span>
                </a>
              </li>

              <li onClick={() => setopen(false)}>
                <a className={`nav-a`} href="#coaching">
                  <span className="first">Coaching</span>
                </a>
              </li>

              <li onClick={() => setopen(false)}>
                <a className={`nav-a`} href="#service">
                  <span className="first">E-Diet Plans</span>
                </a>
              </li>
              
              {/* <li onClick={() => setopen(false)}>
                <NavLink
                  className={` nav-a`}
                  to={"/Supplements"}
                 
                >
                  <span className="first">Supplements</span>
                </NavLink>
              </li> */}
              <li onClick={() => setopen(false)}>
                <a className={` nav-a`} href="#portfolio">
                  <span className="first">Transformations</span>
                </a>
              </li>
              <li onClick={() => setopen(false)}>
                <a className={` nav-a`} href="#news">
                  <span className="first">News</span>
                </a>
              </li>
              <li onClick={() => setopen(false)}>
                <a className={` nav-a`} href="#contact">
                  <span className="first">Contact</span>
                </a>
              </li>
            

            </div>
          </div>

          <div className="social-links-1">
            <ul className="ul-icons-side">
              {SocialShare.map((val, i) => (
                <li key={i}>
                  <a
                    className="icons-side"
                    href={val.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {val.iconName}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </StyledOffCanvas>
      <StyledOffCanvasOverlay isOpen={open} onClick={() => setopen(false)} />
    </>
  );
};

export default Header;
