import React from "react";
import Header2 from "../../components/header/Header2";

const Term = () => {
  return (
    <>
      <Header2 />

      <div className="policy-d1">
        <div className="policy-d2">
          <h1>Terms & Conditions</h1>

          <div className="policy-details">
            <p>
              Please read the following Terms & Conditions carefully before
              using TheShapeMe's services or participating in any courses. By
              accessing our website or enrolling in our courses, you agree to
              comply with and be bound by these terms.
            </p>
            <h1>Acceptance of Terms:</h1>
            <p>
              By using TheShapeMe's services, you acknowledge that you have
              read, understood, and agree to be bound by these Terms &
              Conditions.
            </p>
            <h1>User Eligibility:</h1>
            <p>
              Users must be at least 18 years old to enroll in our courses or
              use our services. By accessing our platform, you confirm that you
              meet this age requirement.
            </p>
            <h1>Course Enrollment:</h1>
            <p>
              Enrollment in courses offered by TheShapeMe is subject to
              availability and acceptance. We reserve the right to refuse or
              cancel enrollment at our discretion.
            </p>
            <h1>Intellectual Property:</h1>
            <p>
              All content, including but not limited to course materials, text,
              graphics, logos, images, and videos, is the property of TheShapeMe
              and is protected by intellectual property laws. Unauthorized use
              or reproduction is strictly prohibited.
            </p>
            <h1>User Conduct:</h1>
            <p>
              Users agree to conduct themselves in a respectful and responsible
              manner while using TheShapeMe's services. Any form of harassment,
              offensive behavior, or violation of the community guidelines may
              result in termination of access.
            </p>
            <h1>Payment and Billing:</h1>
            <p>
              Payment for courses or services is due at the time of enrollment.
              TheShapeMe uses secure payment gateways, and all financial
              information is handled in accordance with our Privacy Policy.
            </p>
            <h1>Refund & Cancellation:</h1>
            <p>
              Please refer to our Refund & Cancellation Policy for information
              on refunds, cancellations, and transferability of course
              enrollments.
            </p>
            <h1>Privacy Policy:</h1>
            <p>
              TheShapeMe is committed to protecting user privacy. Our Privacy
              Policy outlines how we collect, use, and safeguard personal
              information. By using our services, you agree to the terms
              outlined in our Privacy Policy.
            </p>
            <h1>Modification of Terms:</h1>
            <p>
              TheShapeMe reserves the right to modify, update, or change these
              Terms & Conditions at any time. Users will be notified of such
              changes, and continued use of our services implies acceptance of
              the modified terms.
            </p>
            <h1>Termination of Services:</h1>
            <p>
              TheShapeMe reserves the right to terminate or suspend services to
              users who violate these Terms & Conditions, without prior notice.
            </p>
            <h1>Governing Law:</h1>
            <p>
              These Terms & Conditions are governed by and construed in
              accordance with the laws of India Any disputes
              arising out of or related to these terms will be subject to the
              exclusive jurisdiction of the courts in India.
            </p>
            <p>
            If you have any questions or concerns regarding these Terms & Conditions, please contact us at [contact@theshapeme.com].
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Term;
