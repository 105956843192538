import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router";
import { FaXmark } from "react-icons/fa6";
const plans = [
  {
    id: 1,
    img: "img/sid/logo/logo.jpeg",
    category: "program",
    title: " 100 Days Transformation Program",
    price: 8000,
    price2: 5600,
    discount: 30,
    month: "3 Months",
    pdf: false,
    // description:
    //   "MRP - 8000 , Selling Price 6000(Additional 1000₹ off on coupon code - BALANGIR)",
    p1: true,
    p2: true,
    p3: true,
    p4: true,
    p5: true,
    p6: false,
    p7: false,
    p8: false,
    p9: false,
    poin1:
      "Personalized diet plan tailored to your food choices, busy schedule, and budget.",
    poin2: "Personalized workout plan considering your capacity and experience",
    poin3: "Recorded videos for diet recipes and workout tutorials.",
    poin4: "Optimal natural health and wellness supplementation plan.",
    poin5: "Consistent motivation and support with weekly check-ins and calls.",
    poin6:
      "Personalized skincare and haircare routine plan, plus call  appointment with a specialist dermatologist.",
    poin7:
      "Daily check-ins & weekly Zoom calls for ongoing monitoring & motivation..",
    point8:
      "Continuous consultation until your physique goal is reached, no additional renewal fees.",
    point9:
      "Certificate of Achievement and prize on reaching your desired fitness goal.",
  },

  {
    id: 2,
    img: "img/sid/logo/logo.jpeg",
    category: "program",

    title: "Premium Health and Wellness Program",
    price: 25000,
    price2: 15000,
    pdf: false,

    discount: 40,

    month: "6 Months",
    // description:
    //   "MRP - 8000 , Selling Price 6000(Additional 1000₹ off on coupon code - BALANGIR)",
    p1: true,
    p2: true,
    p3: true,
    p4: true,
    p5: true,
    p6: true,
    p7: true,
    p8: true,
    p9: true,

    poin1:
      "Personalized diet plan tailored to your food choices, busy schedule, and budget.",
    poin2:
      " Personalized workout plan considering your capacity and experience.",
    poin3: "Recorded videos for diet recipes and workout tutorials.",
    poin4: "Optimal natural health and wellness supplementation plan.",
    poin5: "Consistent motivation and support with weekly check-ins and calls.",
    poin6:
      "Personalized skincare and haircare routine plan, plus call  appointment with a specialist dermatologist.",
    poin7:
      "Daily check-ins & weekly Zoom calls for ongoing monitoring & motivation..",
    point8:
      "Continuous consultation until your physique goal is reached, no additional renewal fees.",
    point9:
      "Certificate of Achievement and prize on reaching your desired fitness goal.",
  },
];

const SkillsAnimation = () => {
  const navigation = useNavigate();

  return (
    <>
      <div className="beny_tm_title_holder" id="Programs">
        <span>Programs</span>
      </div>
      <div className="beny_tm_resume">
        <div className="main_title">
          <h3>Health and Wellness Programs</h3>
        </div>
        <div className="resume_inner">
          <div className="cards-price-section">
            {plans.map((item, index) => (
              <div
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="50"
                className="card-price1"
              >
                <div className="card-title-d">
                  <h1 className="card-title">{item.title}</h1>
                  <div className="card-pr1">
                    <h1 className="card-price">
                      MRP :{" "}
                      <span style={{ textDecoration: " line-through" }}>
                        ₹{item.price}
                      </span>{" "}
                      <span style={{ color: "#f52225" }}>₹{item.price2} </span>
                    </h1>
                    <span style={{ fontSize: "12px", color: "#f52225" }}>
                      -{item.discount}%
                    </span>
                  </div>

                  <p className="desciption-card">{item.description}</p>
                  <div className="points-d1">
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p1 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.poin1}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p2 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.poin2}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p3 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.poin3}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p4 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.poin4}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p5 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.poin5}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p6 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.poin6}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p7 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.poin7}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p8 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.point8}</h1>
                    </div>
                    <div className="price-point-d">
                      <div className="check-icon-d">
                        {item?.p9 ? (
                          <FaCheck size={10} />
                        ) : (
                          <FaXmark size={12} />
                        )}
                      </div>

                      <h1 className="price-points">{item.point9}</h1>
                    </div>
                  </div>

                  <div
                    className="btn-start-d"
                    onClick={() => navigation("/CheckOut", { state: item })}
                  >
                    <h1>Click here to get started !</h1>
                    <MdArrowOutward size={20} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillsAnimation;
