import React, { useEffect, useState } from "react";
import Currency from "react-currency-formatter";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Modal from "react-modal";

import ReactWhatsapp from "react-whatsapp";
import Footer from "../../components/footer/Footer";
import { CiCircleCheck } from "react-icons/ci";
const Check1 = ({ item }) => {
  const navigation = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
    navigation("/");
    // setData(null);
  };

  const calculateDiscount = (originalPrice, discountedPrice) => {
    const discountAmount = originalPrice - discountedPrice;
    const discountPercentage = (discountAmount / originalPrice) * 100;
    return discountPercentage.toFixed(2); // Limit to 2 decimal places
  };

  const [couponCode, setCouponCode] = useState("");
  const [itemPrice, setItemPrice] = useState(item?.price2);
  const [applied, setApplied] = useState(true);
  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setweight] = useState("");
  const [gender, setGender] = useState("");

  const [age, setAge] = useState("");
  const [category, setCategory] = useState("");
  const [expericence, setExpericence] = useState("");

  const [workoutTime, setWorkoutTime] = useState("");
  const [goal, setGoal] = useState("");
  const [addiction, setAddiction] = useState("");
  const [specificAnswer, setSpecificAnswer] = useState("");
  const [Coupon, setCoupon] = useState(false);

  const [transactionId, settransactionId] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const [details, setDetails] = useState([]);

  const applyCoupon = () => {
    if (!couponCode) {
      toast.error("Fill Required Fields");
    } else {
      if (couponCode === "BGR10") {
        // Apply a 10% discount to the item's price
        const discountedPrice = itemPrice - (itemPrice * 10) / 100;
        setItemPrice(discountedPrice);
        setApplied(false);
        setCoupon(true);
        toast.success("Coupon Applied Successfully");
      } else {
        toast.error("Coupon Not Exist");

        setItemPrice(item?.price2);
      }
    }
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  // State to keep track of the selected state
  const [selectedState, setSelectedState] = useState("");

  // Handle state selection
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    if (
      item.category === "program"
        ? !name ||
          !phone ||
          !email ||
          !height ||
          !weight ||
          !gender ||
          !age ||
          !category ||
          !expericence ||
          !workoutTime ||
          !goal
        : !name ||
          !phone ||
          !email ||
          !height ||
          !weight ||
          !gender ||
          !age ||
          !goal
    ) {
      toast.error("Fill the Required");
    } else {
      try {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
          alert("You are offline... Failed to load Razorpay SDK");
        }

        const options = {
          key: "rzp_live_Z3f7BA8lkXjBRa",
          // key: "rzp_test_w71iPMdJlOE1U2",
          image: "img/sid/logo/logo.jpeg",
          currency: "INR",
          amount: itemPrice * 100,
          name: "The Shape Me",
          description: "Thanks for purchasing",
          theme: {
            color: "#f52225",
          },
          handler: async (response) => {
            try {
              // Check if payment was successful
              if (response.razorpay_payment_id) {
                setIsOpen(true);
                settransactionId(response.razorpay_payment_id);

                toast.success("Payment Successfully");

                const data = {
                  name,
                  phone,
                  email,
                  height,
                  weight,
                  gender,
                  age,
                  category,
                  expericence,
                  workoutTime,
                  goal,
                  addiction,
                  specificAnswer,
                  totalprice: itemPrice,
                  plan: item?.title,
                  price: item?.price,
                  Subtotal: item?.price2,
                  Discount: item.discount,
                  Coupon: Coupon,
                  PaymentId: response.razorpay_payment_id,
                };

                setDetails(data);
                try {
                  await emailjs.send(
                    "service_r5p6n4h",

                    "template_rx4k9cg",
                    data,
                    "CD2QmSOicWFjOHqSK"
                  );

                  setName("");
                  setEmail("");
                  setHeight("");
                  setweight("");
                  setGender("");
                  setweight("");
                  setPhone("");
                  setAge("");
                  setCategory("");
                  setExpericence("");
                  setWorkoutTime("");
                  setGoal("");
                  setAddiction("");
                  setSpecificAnswer("");
                } catch (error) {
                  console.error("Email error:", error);
                }
              } else {
                toast.error("Payment Failed");
              }
            } catch (error) {
              toast.error(error.message);
            }
          },

          prefill: {
            name: name,
            email: email,
            contact: phone,
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const message = `
 Hi, My name is ${details.name} , ${details.email} or ${details.phone}  and I am enrolled in ${details?.plan} and  Payment transaction Id is ${transactionId} .

  `;

  return (
    <>
      {transactionId === null ? (
        <>
          <div className="check-1">
            <div className="check-2">
              <div className="check-lh">
                <div className="check-lh2">
                  {item.category === "Supplement" ? (
                    <h1 className="check-lh-h1">ADDRESS DETAILS</h1>
                  ) : (
                    <h1 className="check-lh-h1">SHAPE ME - ENROLLMENT FORM</h1>
                  )}

                  {item.category === "Supplement" ? (
                    <div>
                      <div className="enroll-form ">
                        <h1 className="check-lh-h3">Contact</h1>
                        <div className="div-check-in">
                          <div className="input-check-div2">
                            <input
                              placeholder="Email"
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>

                        <h1 className="check-lh-h2">Delivery</h1>

                        <div className="div-check-in">
                          <div className="input-check-div">
                            <input
                              placeholder="First Name"
                              type="text"

                              // value={age}
                              // onChange={handleAgeChange}
                            />
                          </div>
                          <div className="input-check-div">
                            <input
                              placeholder="Last Name"
                              type="number"

                              // value={age}
                              // onChange={handleAgeChange}
                            />
                          </div>
                        </div>

                        <div className="div-check-in">
                          <div className="input-check-div2">
                            <input
                              placeholder="Address"
                              type="text"
                              // value={age}
                              // onChange={handleAgeChange}
                            />
                          </div>
                        </div>

                        <div className="div-check-in">
                          <div className="input-check-div">
                            <input
                              placeholder="City"
                              type="text"
                              // value={age}
                              // onChange={handleAgeChange}
                            />
                          </div>
                          <div className="input-check-div">
                            <input
                              placeholder="Pincode"
                              type="number"
                              // value={age}
                              // onChange={handleAgeChange}
                            />
                          </div>
                        </div>
                        <div className="div-check-in">
                          <div className="input-check-div">
                            <select
                              id="stateSelect"
                              value={selectedState}
                              onChange={handleStateChange}
                            >
                              <option value="">Select a state</option>
                              {indianStates.map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                            </select>

                            {/* {selectedState && <p>Selected State: {selectedState}</p>} */}
                          </div>

                          <div className="input-check-div">
                            <input
                              placeholder="Apartment, Flat, etc. (optional)"
                              type="text"
                              // value={age}
                              // onChange={handleAgeChange}
                            />
                          </div>
                        </div>
                        <div className="div-check-in">
                          <div className="input-check-div2">
                            <input
                              placeholder="Phone"
                              type="number"
                              // value={age}
                              // onChange={handleAgeChange}
                            />
                          </div>
                        </div>

                        <h1 className="check-lh-h2">Shipping method</h1>

                        <div className="shipp-m1">
                          <h1>Standard Shipping (2-7 Business Days)</h1>
                          <h2>Cod not available</h2>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="enroll-form ">
                        <div className="div-check-in">
                          <div className="input-check-div2">
                            <input
                              placeholder="Email"
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="div-check-in">
                          <div className="input-check-div">
                            <input
                              placeholder="Name"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="input-check-div">
                            <input
                              placeholder="Contact No"
                              type="number"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="div-check-in">
                          <div className="input-check-div">
                            <input
                              placeholder=" Height"
                              type="number"
                              value={height}
                              onChange={(e) => setHeight(e.target.value)}
                            />
                          </div>
                          <div className="input-check-div">
                            <input
                              placeholder="Weight"
                              type="number"
                              value={weight}
                              onChange={(e) => setweight(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="div-check-in">
                          <div className="input-check-div">
                            <select
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="Gender">Gender</option>

                              <option value="Male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>

                          <div className="input-check-div">
                            <input
                              placeholder="Age"
                              type="number"
                              value={age}
                              onChange={(e) => setAge(e.target.value)}
                            />
                          </div>
                        </div>

                        {item.category === "program" && (
                          <>
                            <div className="div-check-in">
                              <div className="input-check-div">
                                <select
                                  value={category}
                                  onChange={(e) => setCategory(e.target.value)}
                                >
                                  <option value="Choose">
                                    WHAT TYPE OF FOOD YOU PREFER?
                                  </option>
                                  <option value="PURE VEGETARIAN">
                                    PURE VEGETARIAN
                                  </option>

                                  <option value="NON VEGETARIAN">
                                    NON VEGETARIAN
                                  </option>
                                  <option value="EGGITARIAN">EGGITARIAN</option>
                                  <option value="VEGAN">VEGAN</option>
                                </select>
                              </div>

                              <div className="input-check-div">
                                <select
                                  value={expericence}
                                  onChange={(e) =>
                                    setExpericence(e.target.value)
                                  }
                                >
                                  <option value="Choose">
                                    ANY PAST WORKOUT EXPERIENCE?
                                  </option>
                                  <option value="NONE. I AM BEGINNER">
                                    NONE. I AM BEGINNER
                                  </option>

                                  <option value="3 MONTHS TO 6 MONTHS">
                                    3 MONTHS TO 6 MONTHS
                                  </option>
                                  <option value="6 MONTHS TO 1 YEAR">
                                    6 MONTHS TO 1 YEAR
                                  </option>
                                  <option value="1 YEAR TO 3 YEARS">
                                    1 YEAR TO 3 YEARS
                                  </option>
                                  <option value="3 YEAR OR ABOVE">
                                    3 YEAR OR ABOVE
                                  </option>
                                </select>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="div-check-in">
                          {item.category === "program" ? (
                            <>
                              <div className="input-check-div">
                                <select
                                  value={workoutTime}
                                  onChange={(e) =>
                                    setWorkoutTime(e.target.value)
                                  }
                                >
                                  <option value="Choose">
                                    PREFERABLE WORKOUT TIME?
                                  </option>
                                  <option value="MORNING">MORNING</option>

                                  <option value="AFTERNOON">AFTERNOON</option>
                                  <option value="EVENING">EVENING</option>
                                  <option value="NIGHT">NIGHT</option>
                                </select>
                              </div>

                              <div className="input-check-div">
                                <select
                                  value={goal}
                                  onChange={(e) => setGoal(e.target.value)}
                                >
                                  <option value="Choose">
                                    YOUR FITNESS GOAL?
                                  </option>
                                  <option value="FAT LOSS">FAT LOSS</option>

                                  <option value="MUSCLE BUILDING">
                                    MUSCLE BUILDING
                                  </option>
                                  <option value="WEIGHT GAIN">
                                    WEIGHT GAIN
                                  </option>
                                  <option value="FITNESS MODEL(6 PACK ABS)">
                                    FITNESS MODEL(6 PACK ABS)
                                  </option>
                                </select>
                              </div>
                            </>
                          ) : (
                            <div className="input-check-div input-choose-goal">
                              <select
                                value={goal}
                                onChange={(e) => setGoal(e.target.value)}
                              >
                                <option value="Choose">
                                  YOUR FITNESS GOAL?
                                </option>
                                <option value="FAT LOSS">FAT LOSS</option>

                                <option value="MUSCLE BUILDING">
                                  MUSCLE BUILDING
                                </option>
                                <option value="WEIGHT GAIN">WEIGHT GAIN</option>
                                <option value="FITNESS MODEL(6 PACK ABS)">
                                  FITNESS MODEL(6 PACK ABS)
                                </option>
                              </select>
                            </div>
                          )}
                        </div>
                        <div className="div-check-in">
                          <div className="input-check-div2">
                            <input
                              placeholder="ANY SPECIFIC HEALTH ISSUE,INJURY OR ADDICTION? (Optional)"
                              type="text"
                              value={addiction}
                              onChange={(e) => setAddiction(e.target.value)}
                            />
                          </div>
                        </div>
                        {item.category === "program" && (
                          <>
                            <div className="check-lh-d4">
                              <h1>
                                ANY SPECIFIC THING YOU WANT ME TO TAKE INTO
                                CONSIDERATION <br />
                                WHILE MAKING YOUR DIET PLAN?
                              </h1>
                              <ul className="check-ul-lh">
                                <li>LIKE/DISLIKE FOR FOOD</li>
                                <li>MEALS TIMING ISSUE</li>
                                <li>ALLERGY FROM ANY FOOD</li>
                                <li>BUDGET FRIENDLY FOOD OPTIONS</li>
                                <li>
                                  ANY EXISTING SUPPLEMENT YOU ARE USING NOW
                                </li>
                              </ul>
                              <span>Optional</span>

                              <div className="div-check-in">
                                <div className="input-check-div2">
                                  <input
                                    placeholder="YOUR ANSWER? "
                                    type="text"
                                    value={specificAnswer}
                                    onChange={(e) =>
                                      setSpecificAnswer(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="check-rh">
                <div className="chec-rh-f1">
                  <h1 className="check-rh-h1">Your order</h1>

                  <div className="check-rh-d1">
                    <div className="check-rh-d2">
                      <h1>Product</h1>
                      <h1>Subtotal</h1>
                    </div>
                    <div className="package-choose">
                      <div className="package-choose-d2">
                        <img src={item?.img} alt="" />
                        <h1>
                          {item?.title} <br />{" "}
                          {item?.month && <>PLAN FOR {item?.month}</>}
                        </h1>
                      </div>

                      <h1>
                        <Currency quantity={item?.price} currency="INR" />
                      </h1>
                    </div>

                    <div className=" check-rh-d3">
                      <h1>Subtotal</h1>

                      <h1>
                        {" "}
                        <Currency quantity={item?.price2} currency="INR" />
                      </h1>
                    </div>

                    <div className=" check-rh-d4">
                      <h1>Discount</h1>

                      <h1>-{calculateDiscount(item?.price, item?.price2)}%</h1>
                    </div>
                    {applied === false && (
                      <div className=" check-rh-d4">
                        <h1>Coupon Discount</h1>

                        <h1> -10%</h1>
                      </div>
                    )}

                    <div className=" check-rh-d4">
                      <h1>Total</h1>

                      <h1>
                        {" "}
                        <Currency quantity={itemPrice} currency="INR" />
                      </h1>
                    </div>
                  </div>

                  {applied && (
                    <div className="coupon-section">
                      <div className="coupon-input-div">
                        <input
                          placeholder="Coupon Code"
                          type="text"
                          value={couponCode}
                          onChange={handleCouponCodeChange}
                        />
                      </div>
                      <button onClick={applyCoupon}>Apply</button>
                    </div>
                  )}

                  <button onClick={displayRazorpay} className="pay-now-check">
                    PAY NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="after-payment"></div>
      )}

      <Modal
        isOpen={isOpen}
        contentLabel="My dialog"
        className="custom-modal2"
        overlayClassName="custom-overlay1"
        closeTimeoutMS={500}
      >
        <div className="beny_tm_modalbox_service">
          <button className="close-modal" onClick={closeModal}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
        </div>
        <div className="box_inner2">
          <div className="description_wrap scrollable">
            <div className="popup_informations">
              <div className="modal-pay-success">
                <div className="modal-pay-success-7">
                  <h1 className="heading-success">Payment Successfull !</h1>
                  <div className=" modal-pay-success1">
                    <CiCircleCheck size={100} color="#f52225" />
                  </div>
                </div>
                <div className="modal-pay-success-4">
                  <div className="modal-pay-success-3">
                    <h1>Name</h1>
                    <h2>{details.name}</h2>
                  </div>
                  <div className="modal-pay-success-3">
                    <h1>Contact No</h1>
                    <h2>{details.phone}</h2>
                  </div>
                  <div className="modal-pay-success-3">
                    <h1>Email</h1>
                    <h2>{details.email}</h2>
                  </div>
                  <div className="modal-pay-success-5 ">
                    <h1>Amount Paid</h1>
                    <h2>
                      <Currency quantity={itemPrice} currency="INR" />
                    </h2>
                  </div>
                  <div className="modal-pay-success-6">
                    <h1>Transaction id</h1>
                    <h2>{transactionId}</h2>
                  </div>
                </div>

                <div className="modal-pay-success-8">
                  {item.pdf === true && (
                    <>
                      {item?.dietplan === 1 && (
                        <a
                          className="download-pdf"
                          href="img/sid/DietPlanPdf/NON-VEG-KETO-DIET-PLAN.pdf"
                          download
                        >
                          Download Diet Plan
                        </a>
                      )}
                      {item?.dietplan === 2 && (
                        <a
                          className="download-pdf"
                          href="img/sid/DietPlanPdf/PCOS-DIET-PLAN.pdf"
                          download
                        >
                          Download Diet Plan
                        </a>
                      )}
                      {item?.dietplan === 3 && (
                        <a
                          className="download-pdf"
                          href="img/sid/DietPlanPdf/KETO-DIET-PLAN.pdf"
                          download
                        >
                          Download Diet Plan
                        </a>
                      )}
                    </>
                  )}

                  <ReactWhatsapp number={"+91 82492 31436"} message={message}>
                    Send Me
                  </ReactWhatsapp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Check1;
