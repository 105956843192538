import React from "react";
import "./Supplement.css";
import Header2 from "../../components/header/Header2";
import Supplement1 from "./Supplement1";
import Footer from "../../components/footer/Footer";
const Supplement = () => {
  return (
    <>
      <Header2 />

      <Supplement1 />

      <div className="beny_tm_copyright">
        <div className="container">
          <Footer/>
        </div>
      </div>
    </>
  );
};

export default Supplement;
