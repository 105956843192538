import React from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { BsYoutube } from "react-icons/bs";
const SocialShare = [
  {
    iconName: <FiInstagram />,
    link: "https://www.instagram.com/shapeme_diet_expert/",
  },
  {
    iconName: <BsYoutube />,
    link: "https://youtube.com/@shapeme4091?si=EeaT9fyojQd4G4UB",
  },
  {
    iconName: <FiLinkedin />,
    link: "https://www.linkedin.com/feed/",
  },
];
const Social = () => {
  return (
    <ul>
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            {val.iconName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
