import React from "react";
import Preview from "../views/Preview";
import HomeLightAnimation from "../views/all-home-version/HomeLightAnimation";

import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import CheckOut from "../views/all-home-version/CheckOut";
import Privacy from "../views/Policy/Privacy";
import Term from "../views/Policy/Term";
import Refund from "../views/Policy/Refund";
import Supplement from "../views/Supplement/Supplement";

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        {/* <Route path="/" element={<Preview />} /> */}
        <Route path="/" element={<HomeLightAnimation />} />
        <Route path="/Supplements" element={<Supplement />} />
        <Route path="/CheckOut" element={<CheckOut />} />
        <Route path="/PrivacyPolicy" element={<Privacy />} />
        <Route path="/Terms&Conditions" element={<Term />} />
        <Route path="/Refund&CancellationPolicy" element={<Refund />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
