import React from "react";

const Address = () => {
  return (
    <ul>
      <li>
        <img className="svg" src="img/svg/phone.svg" alt="" />
        <a href="Tel: 918249231436">+91 8249231436</a>
      </li>
      {/* End li */}

      <li>
        <img className="svg" src="img/svg/mail.svg" alt="" />
        <span>
          <a href="mailto:1698sidd@gmail.com">1698sidd@gmail.com</a>
        </span>
      </li>
      {/* End li */}

      <li>
        <a target="blank" href="https://maps.app.goo.gl/hwG5W2DtiwWJDhrv8">
          <img className="svg" src="img/svg/map.svg" alt="" />
          <span style={{ textTransform: "capitalize" }}>
            Shape me (Health & Wellness Consultancy ).
         
          </span>
        </a>
      </li>
    </ul>
  );
};

export default Address;
