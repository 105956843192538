import React, { useEffect, useState } from 'react'
import Header2 from '../../components/header/Header2'
import Check1 from './Check1'
import './Check.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
const CheckOut = () => {
  const navigation = useNavigate();

  const location = useLocation();
  const item = location.state;


  useEffect(() => {
    if (item === null) {
      navigation("/");
    }
  }, [item]);




  return (
  <>
  
  <Header2/>

  <Check1 item={item} />
  

  <div className="beny_tm_copyright">
        <div className="container">
          <Footer/>
        </div>
      </div>
  </>
  )
}

export default CheckOut