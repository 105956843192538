import React, { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-carousel-minimal";

Modal.setAppElement("#root");

const ModalContent = ({ closeModal, modalData }) => {
  const navigation = useNavigate();

  const item = modalData;

  if (!modalData || !modalData.image || !Array.isArray(modalData.image)) {
    return null; // or render a message or fallback content
  }

  const data = modalData.image.map((imgData) => ({
    image: imgData.img,
    caption: `Image ${imgData.id}`,
  }));
  const captionStyle = {
    // add your caption style if needed
  };

  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <div className="beny_tm_modalbox_service">
      <button className="close-modal" onClick={closeModal}>
        <img src="/img/svg/cancel.svg" alt="close icon" />
      </button>
      {/* End close icon */}

      <div className="box_inner">
        <div className="description_wrap scrollable">
          <div className="carousel-imgs">
            <Carousel
              data={data}
              time={3000}
              width="100%"
              height="400px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={false}
              slideNumberStyle={slideNumberStyle}
              
              automatic={true}
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={false}
              thumbnailWidth="100px"
            />

            <div className="description des-modal des-modal-sup">
              <div className="head-modal-d1">
                <div className="head-modal-d2">
                  <h1>{modalData?.title}</h1>
                  <h3 className="head-2-modal">{modalData?.heading}</h3>
                </div>
                <div className="head-modal-d3">
                  <h2>
                    mrp{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      ₹{modalData?.price}
                    </span>{" "}
                    <span style={{ color: "#f52225" }}>
                      {" "}
                      ₹{modalData?.price2}
                    </span>
                  </h2>

                  <span style={{ fontSize: "12px", color: "#f52225" }}>
                    -{modalData?.discount}%
                  </span>
                </div>
              </div>
              <div>
                <button
                  onClick={() => navigation("/CheckOut", { state: item })}
                  className="modal-button1"
                >
                  Buy Now
                </button>
              </div>

              <div className="in_list">
                <ul>
                  <li>
                    <p>{modalData?.description1}</p>
                  </li>
                  <li>
                    <p>{modalData?.description2}</p>
                  </li>
                  <li>
                    <p>{modalData?.description3}</p>
                  </li>
                  <li>
                    <p>{modalData?.description4}</p>
                  </li>
                  <li>
                    <p>{modalData?.description5}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End box inner */}
    </div>
  );
};

const Supplement1 = () => {
  const [modalData, setModalData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = (item) => {
    setIsOpen(!isOpen);
    setModalData(item);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalData(null);
  };

  const plans = [
    {
      id: 1,
      title: "Dymatize Elite",
      heading: "100% Whey Protein, 2.27 kg (5 lb), Rich Chocolate",
      price: 11999,
      price2: 7389,
      discount: 38.42,
      category: "Supplement",
      img: "/img/sid/supplement/sup1.jpg",
      image: [
        { id: 1, img: "/img/sid/supplement/sup1.jpg" },
        { id: 2, img: "/img/sid/supplement/sup2.jpg" },
        { id: 2, img: "/img/sid/supplement/sup3.jpg" },
        { id: 2, img: "/img/sid/supplement/sup4.jpg" },
        { id: 2, img: "/img/sid/supplement/sup5.jpg" },
      ],
      description1:
        "1 : Each scoop gives you 25 grams of protein blend including whey protein isolates, concentrates, and whey peptides.",
      description2:
        "2.⁠ ⁠Easy and Sustainable Recipes: Follow along with recorded recipe videos showcasing easy-to-make and sustainable keto meals, tailored to both vegetarian and non-vegetarian preferences",
      description3:
        "3.⁠ This supplement is prepared with cross-flow microfiltration, guaranteed pure and unadulterated proteins",
      description4:
        "4.⁠⁠ Contains BCAAs and L-leucine to help in faster muscle recovery.",
      description5:
        "5.⁠ Fast digesting proteins help you get the best possible results. Prevents any muscle fatigue and muscle loss to improve your performance.",
    },
    {
      id: 2,
      title: "ON (Optimum Nutrition) Gold Standard",
      heading: "100% Whey Protein, 2.5 kg (5.5 lb), Double Rich Chocolate",
      price: 8099,
      price2: 6884,
      discount: 15,
      category: "Supplement",

      img: "/img/sid/supplement/pro-2-1.jpg",
      image: [
        { id: 1, img: "/img/sid/supplement/pro-2-1.jpg" },
        { id: 2, img: "/img/sid/supplement/pro-2-2.jpg" },
        { id: 3, img: "/img/sid/supplement/pro-2-3.jpg" },
        { id: 4, img: "/img/sid/supplement/pro-2-4.jpg" },
       
      ],
      description1:
        "1. Complete High-Protein Indian Food Plan: Get a simple guide with veg and non-veg options to help you get slim and fit by eating Indian food with lots of protein.",
      description2:
        "2. Tasty and Healthy Recipes: Watch easy videos for making yummy and healthy high-protein meals inspired by Indian cooking to support your fitness goals.",
      description3:
        "3. ⁠Clear Fitness Goals: Decide what you want for your body, like getting toned or losing weight, and use these recipes to help you reach those goals.",
      description4:
        "4.⁠ ⁠Fit into Your Routine: Learn how to easily fit these protein-rich Indian dishes into your daily life so you can stay healthy and fit for the long term.",
      description5:
        "5.⁠ ⁠ ⁠Extra Help When You Need It: Find more tips and resources to support you as you work towards a healthier and fitter you with these high-protein Indian recipes.",
    },
    {
      id: 3,
      title: "Dymatize Iso-100 Protein",
      heading: " 2.27 kg (5 lb), Gourmet Chocolate",
      price: 16999,
      price2: 12744,
      discount: 25,
      category: "Supplement",
      img: "/img/sid/supplement/sup2-1.jpg",
      image: [
        { id: 1, img: "/img/sid/supplement/sup2-1.jpg" },
        { id: 2, img: "/img/sid/supplement/sup2-2.jpg" },
        { id: 3, img: "/img/sid/supplement/sup2-3.jpg" },
        
       
      ],
      description1:
        "1. Complete High-Protein Indian Food Plan: Get a simple guide with veg and non-veg options to help you get slim and fit by eating Indian food with lots of protein.",
      description2:
        "2. Tasty and Healthy Recipes: Watch easy videos for making yummy and healthy high-protein meals inspired by Indian cooking to support your fitness goals.",
      description3:
        "3. ⁠Clear Fitness Goals: Decide what you want for your body, like getting toned or losing weight, and use these recipes to help you reach those goals.",
      description4:
        "4.⁠ ⁠Fit into Your Routine: Learn how to easily fit these protein-rich Indian dishes into your daily life so you can stay healthy and fit for the long term.",
      description5:
        "5.⁠ ⁠ ⁠Extra Help When You Need It: Find more tips and resources to support you as you work towards a healthier and fitter you with these high-protein Indian recipes.",
    },

  ];

  return (
    <div className="beny_tm_services  supplement-1 " id="service">
      <div className="container">
        <div className="beny_tm_title_holder">
          <span>Supplements</span>
          <h2>Fuel Your Journey:</h2>
       
        </div>
        <div className="service_list">
          <ul>
            {plans?.map((item, index) => (
              <li key={item.id}>
                <div
                  className="list_inner"
                  onClick={() => toggleModal(item)}
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <img className="svg img-suppl-1" src={item?.img} alt="" />
                  <div className="service_title">
                    <div className="price-dis2">
                      <h3>
                        {item?.title} <br />
                        <span className="supple-h2">{item?.heading}</span>
                      </h3>
                      <div className="price-dis">
                        <h3>
                          mrp :{" "}
                          <span style={{ textDecoration: "line-through" }}>
                            ₹{item?.price}
                          </span>{" "}
                          <span style={{ color: "#f52225" }}>
                            ₹{item?.price2}
                          </span>
                        </h3>
                        <span style={{ fontSize: "12px", color: "#f52225" }}>
                          -{item?.discount}%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="learn_more">
                    Buy Now<span></span>
                  </div>
                </div>

                {/* End Modal Motion Graphy */}
              </li>
            ))}
          </ul>

          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="My dialog"
            className="custom-modal"
            overlayClassName="custom-overlay"
            closeTimeoutMS={500}
          >
            <ModalContent closeModal={closeModal} modalData={modalData} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Supplement1;
