import React, { useEffect, useState } from "react";
import Social from "../Social";
import SocialTwo from "../SocialTwo";
import TextLoop from "react-text-loop";

const SliderAnimation = () => {
  const [width, setWidth] = useState(window.innerWidth <= 700);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    //    HERO
    <div className="beny_tm_hero" id="home">
      <div className="background">
        <div
          className="image"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL +
              ` ${width ? "img/sid/sid2.jpeg" : "img/sid/sid1.jpeg"} `
            })`,
          }}
        ></div>
        <div className="overlay"></div>
      </div>
      {/* End bg */}

      <div className="go-to go-to-next">
        <a href="#about">
          <span></span>
        </a>
      </div>
      {/* End animated goto button */}

      <div className="container">
        <div className="content">
          <div className="content_inner">
            {/* <h3
              className="name"
              style={{ color: "white" }}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              India's first one-stop solution destination for health & fitness.
            </h3> */}
            <h1
              className="job"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="50"
            >
              <TextLoop>
                <p style={{ color: "#f52225" }} className="loop-text lead">
                  India's First <br /> One-stop Solution
                </p>
              
                <p style={{ color: "#f52225" }} className="loop-text lead">
                  Destination for <br /> Health & Fitness.
                </p>
        
              </TextLoop>
            </h1>
            <p
              className="text"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              style={{ color: "white" }}
            >
              Featured on 

              <img
                style={{ width: "40px", borderRadius: "2px",margin:'10px' }}
                src="img/sid/logonews.jpg"
                alt=""
              />

               for guiding 3000+ on their fat loss journey.
            </p>
            <div
              className="mobile_social"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <Social />
            </div>
            {/* End .social */}

            <div
              className="beny_tm_button"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <a className="anchor" href="#Programs">
                <span className="wrapper">
                  <span style={{ color: "white" }} className="first">
                    See Programs
                  </span>
                  <span style={{ color: "white" }} className="second">
                    See Programs
                  </span>
                </span>
              </a>
            </div>
            {/* End beny_tm_button */}
          </div>
        </div>
      </div>
      {/* End .container */}


      {/* End .social */}
    </div>
    //HERO
  );
};

export default SliderAnimation;
